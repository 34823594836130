/**
 * @file standalone/collapsible-block.scss
 *
 * \brief Library file - shared styles for collapsible blocks
 *
 */

@import '../theme-bootstrap';

.collapsible-block {
  border-bottom: $color-off-black solid 1px;
  padding: 1em;
  position: relative;
}

.collapsible-block__title {
  position: relative;
  cursor: pointer;
  @include breakpoint($landscape-up) {
    font-size: 2em;
  }
}

.collapsible-block__icon {
  position: absolute;
  top: 0;
  #{$rdirection}: 1em;
  &::after {
    @include single-transition(
      $default-transition-property,
      $default-transition-duration,
      $default-transition-function
    );
    @include svg-icon-inline-mask('caret--right');
    content: '';
    background-color: $color-off-black;
    width: 14px;
    height: 14px;
    display: inline-block;
    vertical-align: middle;
    transform: rotate(0);
    @include breakpoint($landscape-up) {
      width: 32px;
      height: 32px;
    }
  }
  .collapsible-block--alt & {
    &::after {
      @include svg-icon-inline-mask('caret--right');
      transform: rotate(90deg);
    }
  }
  .collapsible-block--mobile-collapsed & {
    @include breakpoint($medium-down) {
      &::after {
        @include svg-icon-inline-mask('caret--right');
        width: 32px;
        height: 32px;
        transform: rotate(0);
      }
    }
  }
  .collapsible-block--mobile-collapsed.collapsible-block--alt & {
    @include breakpoint($medium-down) {
      &::after {
        @include svg-icon-inline-mask('caret--right');
        transform: rotate(90deg);
      }
    }
  }
}

.collapsible-block__content {
  @include single-transition(
    $default-transition-property,
    $default-transition-duration,
    $default-transition-function
  );
  height: auto;
  overflow: hidden;
  padding-top: 1em;
  .collapsible-block--alt & {
    padding-top: 0;
    height: 0;
  }
  .collapsible-block--mobile-collapsed & {
    @include breakpoint($medium-down) {
      height: auto;
    }
  }
  .collapsible-block--mobile-collapsed.collapsible-block--alt & {
    @include breakpoint($medium-down) {
      height: 0;
      overflow: hidden;
    }
  }
}

.collapsible-block.collapsible-block--mobile-collapsed {
  @include breakpoint($mobile-only) {
    padding-top: 50px;
  }
}

.collapse-plus-minus {
  .collapsible-block {
    border: none;
    margin: 12px;
    padding: 12px;
    &__title {
      text-align: center;
    }
    &__icon {
      @include svg-icon-inline-mask('minus');
      background: $color-off-black;
      top: 5px;
      transform: none;
      use {
        display: none;
      }
    }
    &--alt {
      .collapsible-block__icon {
        @include svg-icon-inline-mask('plus');
      }
      background: $color-gray-bg;
    }
  }
  .salon-grid {
    display: flex;
    flex-direction: column;
  }
  img {
    margin-bottom: 8px;
  }
  .basic-btn {
    align-self: center;
    margin-top: auto;
  }
}
